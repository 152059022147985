import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import {
  identity,
  isEitherAdmin,
  isPartner,
  isProduction,
  isServiceProvider,
  isStartup,
} from 'utils';
import { Input, RenderIf } from 'components/components';
import { ServiceProviderType } from 'types/serviceProviders';
import { PartnerType } from 'packages/partner_repository';
import { RequestFiltersDropdownProps } from 'types/requests';
import { LabeledValue } from 'types';
import { StartupType } from 'types/startups';
import { requestTypeValues, useRequestFiltersHook } from '../../utils';

const RequestFiltersDropdown = (props: RequestFiltersDropdownProps) => {
  const {
    setShowDropdown,
    userType,
    serviceProviders,
    partners,
    startups,
    filters,
  } = props;
  const {
    getWorkspaceServiceProviders,
    getWorkspacePartners,
    getStartups,
    setRequestOverviewFilters,
  } = props.actions;

  useEffect(() => {
    if (!isPartner(userType) && !isServiceProvider(userType)) {
      if (identity.isEmptyArray(serviceProviders)) {
        getWorkspaceServiceProviders();
      }
      if (identity.isEmptyArray(partners)) {
        getWorkspacePartners();
      }
    }

    if (!isStartup(userType) && identity.isEmptyArray(startups)) {
      getStartups();
    }
  }, []);

  const startupsOption: LabeledValue[] = useMemo(() => startups.map(
    ({ id: value, name: label }: StartupType) => ({ label, value, key: value }),
  ), [startups]);

  const serviceProvidersOptions: LabeledValue[] = useMemo(() => serviceProviders.map(
    ({ id: value, name: label }: ServiceProviderType) => ({ label, value, key: value }),
  ), [serviceProviders]);

  const partnersOptions: LabeledValue[] = useMemo(() => partners.map(
    ({ id: value, name: label }: PartnerType) => ({ label, value, key: value }),
  ), [partners]);

  const {
    state,
    initialState,
    setType,
    setAmount,
    setPartners,
    setServiceProviders,
    resetFilters,
    setStartups,
    setAllFilters,
  } = useRequestFiltersHook();

  const {
    type,
    from,
    to,
    serviceProviderId,
    partnerId,
    startupId,
  } = state;

  useEffect(() => {
    setAllFilters({ data: filters });
  }, [filters]);

  const onFilter = () => {
    setRequestOverviewFilters({ data: state });
  }

  const onClose = () => {
    setAllFilters(filters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setRequestOverviewFilters({ data: initialState });
    onClose();
  }

  const isStartupOrAdmin = useMemo(() => isStartup(userType) || isEitherAdmin(userType), [userType]);

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>
      <RenderIf condition={[isStartupOrAdmin, !isProduction].every(Boolean)}>
        <Input label="Request Type" layout="vertical">
          <Input.Radio
            values={requestTypeValues}
            value={type}
            onChange={({ target: { value: data } }) => setType({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={[isStartupOrAdmin, (type === 'service' || isProduction)].every(Boolean)}>
        <Input label="Service provider" layout="vertical">
          <Input.Select
            showSearch
            options={serviceProvidersOptions}
            value={serviceProviderId}
            mode="multiple"
            placeholder="Select service providers"
            maxTagCount="responsive"
            onChange={(data) => setServiceProviders({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={[isStartupOrAdmin, type === 'partner'].every(Boolean)}>
        <Input label="Partner" layout="vertical">
          <Input.Select
            showSearch
            options={partnersOptions}
            value={partnerId}
            mode="multiple"
            placeholder="Select partners"
            maxTagCount="responsive"
            onChange={(data) => setPartners({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={!isStartup(userType)}>
        <Input label="Startup" layout="vertical">
          <Input.Select
            showSearch
            options={startupsOption}
            value={startupId}
            mode="multiple"
            placeholder="Select startups"
            maxTagCount="responsive"
            onChange={(data) => setStartups({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <RenderIf condition={(!isPartner(userType) && (type === 'service' || isProduction)) || isServiceProvider(userType)}>
        <div className="filters-dropdown__amount-container">
          <Input.Heading title="Amount" />
          <div className="filters-dropdown__amount-fields">
            <Input name="from" label="From" layout="vertical">
              <Input.InputNumber
                value={from}
                onChange={(e) => setAmount({ data: { field: 'from', value: identity.isPositiveNumber(e) ? e! : 0 } })}
              />
            </Input>
            <Input name="to" label="To" layout="vertical">
              <Input.InputNumber
                value={to}
                onChange={(e) => setAmount({ data: { field: 'to', value: identity.isPositiveNumber(e) ? e! : 0 } })}
              />
            </Input>
          </div>
        </div>
      </RenderIf>
      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default RequestFiltersDropdown;
