import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import moment from 'moment';

import { identity } from 'utils';
import { Input, RenderIf } from 'components/components';
import { InsuranceColumnsProps } from 'types/services/requestService';
import { formatSlashMDY } from 'components/utils/constants/formatters';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import DependantForm from 'components/RequestServiceDrawer/components/DependantForm';
import TeamMemberDetails from 'components/RequestServiceDrawer/components/TeamMemberDetails';
import { planTypeOptions } from 'components/RequestServiceDrawer/utils/constants';

const InsuranceColumns = (props: InsuranceColumnsProps) => {
  const {
    member,
    state,
    plan,
    getMemberById,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsUserUAERes,
    setIsApplyingForDependant,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setPlan,
    setTeamMember,
    setRequestDocuments,
    setETAWithSignFile,
    setQuantity,
  } = props;

  const {
    teamMember, isApplyingForDependant, displayDocuments,
  } = state.request;
  const [teamMemberId, setTeamMemberId] = useState(0);

  useEffect(() => {
    setTeamMember({ data: member })
  }, [member]);

  useEffect(() => {
    if (identity.isTruthyNumber(teamMemberId)) {
      getMemberById({ id: teamMemberId })
    } else {
      setTeamMember({ data: undefined });
    }
  }, [teamMemberId]);

  useEffect(() => {
    setQuantity({ data: 1 });
  }, []);

  return (
    <div className="overview-columns">
      <RenderIf condition={!displayDocuments}>
        <DataColumn label="Choose team member" className="input">
          <Input.Select
            options={props.teamMembersOptions}
            value={teamMember?.id || 0}
            onChange={(data: number) => setTeamMemberId(data)}
          />
        </DataColumn>
      </RenderIf>
      <RenderIf condition={identity.isTruthyNumber(teamMember?.id)}>
        <div>
          <TeamMemberDetails
            header="Date of birth"
            subheader={identity.isTruthyString(teamMember?.dateOfBirth) ? moment(teamMember?.dateOfBirth).format(formatSlashMDY) : 'Not specified'}
          />
          <RenderIf condition={identity.isTruthyString(teamMember?.gender)}>
            <TeamMemberDetails header="Gender" subheader={teamMember?.gender} />
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf condition={!displayDocuments}>
        <DataColumn label="" className="input">
          <div className="switch-wrap">
            <div className="request-service-details-description">
              <p>Applying for the dependant?</p>
            </div>
            <Switch
              size="default"
              onChange={(e) => setIsApplyingForDependant({ data: e })}
              className="switcher"
            />
          </div>
        </DataColumn>
      </RenderIf>
      <RenderIf condition={isApplyingForDependant!}>
        <DependantForm
          setRelationToTM={setRelationToTM}
          setIsDependantUAE={setIsDependantUAE}
          setDependantGender={setDependantGender}
          setDependantFullName={setDependantFullName}
          setDependantDateOfBirth={setDependantDateOfBirth}
          setChildPlaceOfBirth={setChildPlaceOfBirth}
          setIsUserUAERes={setIsUserUAERes}
          setPassportFile={setPassportFile}
          setVisaFile={setVisaFile}
          setVisaProofFile={setVisaProofFile}
          setEmiratesIDFile={setEmiratesIDFile}
          setRequestDocuments={setRequestDocuments}
          setETAWithSignFile={setETAWithSignFile}
          state={state}
        />
      </RenderIf>
      <RenderIf condition={!displayDocuments}>
        <DataColumn label="Choose plan" className="input">
          <Input.Select
            value={plan}
            placeholder="Enter or choose from dropdown"
            className="quantity-select"
            onChange={(data) => setPlan({ data })}
            options={planTypeOptions}
          />
        </DataColumn>
      </RenderIf>
    </div>
  );
}

export default InsuranceColumns;
