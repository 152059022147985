import React, { useState } from 'react';
import { Button } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { UploadInvoiceFieldsProps } from 'types/requests';
import { Input } from 'components/components';

import './UploadInvoiceFields.scss';

const UploadInvoiceFields = (props: UploadInvoiceFieldsProps) => {
  const { serviceRequest, actions: { setShowInvoiceFields, updateServiceRequest } } = props;
  const [invoiceFile, setInvoiceFile] = useState<string | Blob>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');

  const beforeInvoiceUpload = (file: RcFile) => {
    setInvoiceFile(file as Blob);
    return false;
  }

  const handleSubmit = () => {
    updateServiceRequest({ id: serviceRequest.id, invoice: invoiceFile, invoiceNumber });
  }

  return (
    <div className="upload-invoice-fields">
      <Input label="Invoice number">
        <Input.InputField
          name="invoiceNumber"
          value={invoiceNumber}
          onChange={({ target: { value } }) => setInvoiceNumber(value)}
        />
      </Input>
      <Input label="Total price">
        <Input.InputField name="totalPrice" disabled value={serviceRequest.totalPrice} />
      </Input>
      <Input label="Invoice pdf">
        <Input.DraggerUpload
          beforeUpload={beforeInvoiceUpload}
          previewType="image"
          name="invoice"
          accept=".pdf"
          isRoundableButton
        />
      </Input>
      <div className="upload-invoice-fields__buttons">
        <Button className="secondary-button md" onClick={() => setShowInvoiceFields({ data: false })}>Cancel</Button>
        <Button className="primary-button md" onClick={handleSubmit}>Deliver</Button>
      </div>
    </div>
  );
}

export default UploadInvoiceFields;
