import { PayloadAction } from '@reduxjs/toolkit';

import { RequestType } from 'types/requests';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ServiceType } from 'packages/service_repository';
import { defaultService, defaultRequest } from 'redux/utils';
import { MemberType } from 'types/memberDetails';
import { PriceType, ServicesType } from 'types/services';
import { identity } from 'utils';
import { ServiceRequestItemDetailsType, ServiceRequestDocumentType } from 'packages/service_request_repository';
import { types } from './actions';
import { totalRequestAmountForInsurance } from '..';
import { setDisabledButton } from './utils';

export type RequestServiceHookState = {
  request: RequestType,
  service: ServiceType,
  disabled: boolean,
}

type RequestServiceHookPA =
  PayloadAction<DataPayload<
    ServicesType
    | PriceType
    | boolean
    | number
    | MemberType
    | ServiceRequestItemDetailsType
    | Blob
    | ServiceRequestDocumentType> | {}>

const initialState: RequestServiceHookState = {
  request: defaultRequest,
  service: defaultService,
  disabled: true,
};

const handleSetService = (state: RequestServiceHookState, payload: DataPayload<ServiceType>) => {
  const { data: service } = payload;
  const request = { ...state.request, service };
  const newState = { ...state, service, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetQuantity = (state: RequestServiceHookState, payload: DataPayload<number>) => {
  const { data: quantity } = payload;
  const request = { ...state.request, quantity }
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetPriceType = (state: RequestServiceHookState, payload: DataPayload<PriceType>) => {
  const { data: priceType } = payload;
  const request = { ...state.request, priceType };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetFurnished = (state: RequestServiceHookState, payload: DataPayload<boolean>) => {
  const { data: furnished } = payload;
  const request = { ...state.request, furnished };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled }
}

const handleSetTeamMember = (state: RequestServiceHookState, payload: DataPayload<MemberType | undefined>) => {
  const { data: teamMember } = payload;
  let request = { ...state.request };
  if (identity.isObjWithChildren(teamMember)) {
    request = { ...request, teamMember };
  } else {
    delete request.teamMember;
  }
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetPlan = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: plan } = payload;
  const total = totalRequestAmountForInsurance(plan);
  const request = { ...state.request, plan, total };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetAccommodationType = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: accommodationType } = payload;
  const request = { ...state.request, accommodationType };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetOfficeType = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: officeType } = payload;
  const request = { ...state.request, officeType };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetTotalPrice = (state: RequestServiceHookState, payload: DataPayload<number>) => {
  const { data: totalPrice } = payload;
  const request = { ...state.request, totalPrice, total: `AED ${totalPrice}` };
  const newState = { ...state, request };
  const disabled = setDisabledButton(newState);
  return { ...newState, disabled };
}

const handleSetDetails = (state: RequestServiceHookState, payload: DataPayload<ServiceRequestItemDetailsType>) => {
  const { data: details } = payload;
  return { ...state, request: { ...state.request, details } };
}

const handleSetIsUserUAERes = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: isUserUAEResident } = payload;
  const request = { ...state.request, isUserUAEResident };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleRelationToTM = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: relationToTM } = payload;
  const request = { ...state.request, relationToTM };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetIsDependantUAE = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: isDependantUAE } = payload;
  const request = { ...state.request, isDependantUAE };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetDependantGender = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: dependantGender } = payload;
  const request = { ...state.request, dependantGender };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetDependantFullName = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: dependantFullName } = payload;
  const request = { ...state.request, dependantFullName };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetDependantDateOfBirth = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: dependantDateOfBirth } = payload;
  const request = { ...state.request, dependantDateOfBirth };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetChildPlaceOfBirth = (state: RequestServiceHookState, payload: DataPayload<string>) => {
  const { data: childPlaceOfBirth } = payload;
  const request = { ...state.request, childPlaceOfBirth };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetIsApplyingForDependant = (state: RequestServiceHookState, payload: DataPayload<boolean>) => {
  const { data: isApplyingForDependant } = payload;
  const request = { ...state.request, isApplyingForDependant };
  const disabled = setDisabledButton({ ...state, request });
  return { ...state, request, disabled };
}

const handleSetPassportFile = (state: RequestServiceHookState, payload: DataPayload<string | Blob>) => {
  const { data: passportFile } = payload;
  return { ...state, request: { ...state.request, passportFile } }
};

const handleSetVisaFile = (state: RequestServiceHookState, payload: DataPayload<string | Blob>) => {
  const { data: visaFile } = payload;
  return { ...state, request: { ...state.request, visaFile } }
};

const handleSetVisaProofFile = (state: RequestServiceHookState, payload: DataPayload<string | Blob>) => {
  const { data: visaProofFile } = payload;
  return { ...state, request: { ...state.request, visaProofFile } }
};

const handleSetEmiratesIdFile = (state: RequestServiceHookState, payload: DataPayload<string | Blob>) => {
  const { data: emiratesIdFile } = payload;
  return { ...state, request: { ...state.request, emiratesIdFile } }
};

const handleSetRequestDocuments = (
  state: RequestServiceHookState,
  payload: DataPayload<ServiceRequestDocumentType[]>,
) => {
  const { data: documents } = payload;
  return { ...state, request: { ...state.request, documents } }
};

const handleSetDisplayDocuments = (state: RequestServiceHookState, payload: DataPayload<boolean>) => {
  const { data: displayDocuments } = payload;
  return { ...state, request: { ...state.request, displayDocuments } };
}

const handleSetETAWithSignFile = (state: RequestServiceHookState, payload: DataPayload<string | Blob>) => {
  const { data: ETAWithSign } = payload;
  return { ...state, request: { ...state.request, ETAWithSign } }
};
// eslint-disable-next-line default-param-last
const reducer = (state: RequestServiceHookState = initialState, action: RequestServiceHookPA) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SERVICE: {
      return handleSetService(state, <DataPayload<ServiceType>>payload);
    }
    case types.SET_QUANTITY: {
      return handleSetQuantity(state, <DataPayload<number>>payload);
    }
    case types.SET_PRICE_TYPE: {
      return handleSetPriceType(state, <DataPayload<PriceType>>payload);
    }
    case types.SET_FURNISHED: {
      return handleSetFurnished(state, <DataPayload<boolean>>payload);
    }
    case types.SET_TEAM_MEMBER: {
      return handleSetTeamMember(state, <DataPayload<MemberType>>payload);
    }
    case types.SET_PLAN: {
      return handleSetPlan(state, <DataPayload<string>>payload);
    }
    case types.SET_ACCOMMODATION_TYPE: {
      return handleSetAccommodationType(state, <DataPayload<string>>payload);
    }
    case types.SET_OFFICE_TYPE: {
      return handleSetOfficeType(state, <DataPayload<string>>payload);
    }
    case types.SET_TOTAL_PRICE: {
      return handleSetTotalPrice(state, <DataPayload<number>>payload);
    }
    case types.SET_DETAILS: {
      return handleSetDetails(state, <DataPayload<ServiceRequestItemDetailsType>>payload);
    }
    case types.SET_IS_USER_UAE_RES: {
      return handleSetIsUserUAERes(state, <DataPayload<string>>payload);
    }
    case types.SET_RELATION_TO_TM: {
      return handleRelationToTM(state, <DataPayload<string>>payload);
    }
    case types.SET_IS_DEPENDANT_UAE: {
      return handleSetIsDependantUAE(state, <DataPayload<string>>payload);
    }
    case types.SET_DEPENDANT_GENDER: {
      return handleSetDependantGender(state, <DataPayload<string>>payload);
    }
    case types.SET_DEPENDANT_FULL_NAME: {
      return handleSetDependantFullName(state, <DataPayload<string>>payload);
    }
    case types.SET_DEPENDANT_DATE_OF_BIRTH: {
      return handleSetDependantDateOfBirth(state, <DataPayload<string>>payload);
    }
    case types.SET_CHILD_PLACE_OF_BIRTH: {
      return handleSetChildPlaceOfBirth(state, <DataPayload<string>>payload);
    }
    case types.SET_IS_APPLYING_FOR_DEPENDANT: {
      return handleSetIsApplyingForDependant(state, <DataPayload<boolean>>payload);
    }
    case types.SET_PASSPORT_FILE: {
      return handleSetPassportFile(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_VISA_FILE: {
      return handleSetVisaFile(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_VISA_PROOF_FILE: {
      return handleSetVisaProofFile(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_EMIRATES_ID_FILE: {
      return handleSetEmiratesIdFile(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_REQUEST_DOCUMENTS: {
      return handleSetRequestDocuments(state, <DataPayload<ServiceRequestDocumentType[]>>payload);
    }
    case types.SET_DISPLAY_DOCUMENTS: {
      return handleSetDisplayDocuments(state, <DataPayload<boolean>>payload);
    }
    case types.SET_ETA_WITH_SIGN_FILE: {
      return handleSetETAWithSignFile(state, <DataPayload<string | Blob>>payload);
    }
    default: {
      return state;
    }
  }
}

export { reducer, initialState };
