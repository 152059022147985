import { Button } from 'antd';
import React from 'react';

import { ApprovalButtonsProps } from 'types/startups/startupDetails';
import { isEitherAdmin } from 'utils';
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import Status from './components/Status';
import { isNew } from '../../../../../../RequestsOverview/utils';

import './ApprovalButtons.scss';

const ApprovalButtons = (props: ApprovalButtonsProps) => {
  const {
    status, actions, requestId, component, userType,
  } = props;

  if (!isEitherAdmin(userType)) {
    return null;
  }

  return (
    <div className="approval-buttons">
      {isNew(status)
        ? (
          <div className="approval-buttons__container">
            <Button
              className="primary-button md"
              onClick={() => actions.changeRequestStatus(
                { status: REQUEST_STATUS.quoted, id: requestId, component },
              )}
            >
              Approve
            </Button>
            <Button
              className="secondary-button md"
              onClick={() => actions.changeRequestStatus(
                { status: REQUEST_STATUS.rejected, id: requestId, component },
              )}
            >
              Reject
            </Button>
          </div>
        )
        : <Status component={component} status={status} />}
    </div>
  );
}

export default ApprovalButtons;
