import React, { useEffect } from 'react';

import { ServiceRequestTotalProps } from 'types/services';
import { identity } from 'utils';

import './ServiceRequestTotal.scss'

const ServiceRequestTotal = (props: ServiceRequestTotalProps) => {
  const { price, quantity, setTotalPrice } = props;

  useEffect(() => {
    const data = identity.isTruthyNumber(quantity) ? price * quantity! : 0;
    setTotalPrice({ data });
  }, [quantity, price]);

  return (
    <div className="service-request-total--container">
      <div className="service-request__total">
        <div className="service-request__total-text">Total</div>
        <div className="service-request__total-amount">
          {`AED ${props.totalPrice}`}
        </div>
      </div>
    </div>
  );
}

ServiceRequestTotal.defaultProps = {
  quantity: 1,
}

export default ServiceRequestTotal;
