import React from 'react';

import { identity } from 'utils';
import { AboutProps } from 'types/componentTypes/AboutProps';
import { RenderIf } from 'components/components';

import './About.scss';

const About = (props: AboutProps) => {
  const { title, description } = props;

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>
        <div className="about__card">
          { description || 'No description or details specified' }
          { props.children }
        </div>
      </div>
    </div>
  );
};

export default About;
