import React from 'react';
import classnames from 'classnames';

import { getStatusName, isRejected } from '../../../../../../../../RequestsOverview/utils/helpers';
import { StatusProps } from '../../../../../../../../../types/startups/startupDetails';

const Status = (props: StatusProps) => {
  const { component, status } = props;

  if (component === 'requests') {
    return null;
  }

  return (
    <div className={classnames('approval-buttons__status-container', { 'approval-buttons__status-container--rejected': isRejected(status) })}>
      <span className={classnames('approval-buttons__status', { 'approval-buttons__status--rejected': isRejected(status) })}>{getStatusName(status)}</span>
    </div>
  );
}

Status.defaultProps = {
  component: undefined,
};

export default Status;
