import React, {
  RefObject, useCallback, useEffect, useRef,
} from 'react';
import {
  Form, Button, Spin, InputRef,
} from 'antd';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

import { requiredValidation } from '../../utils/constants/validationRules';
import { LoginProps } from '../../types/auth/LoginProps';
import logo from '../../assets/images';
import Input from '../components/Input';
import routes from '../../routes';

import './Login.scss';

const Login = (props: LoginProps) => {
  const { isLoading, actions, isAuthenticated } = props;
  const usernameRef = useRef<InputRef>();

  const onFinish = useCallback(() => (data: object) => actions.login(data), []);

  useEffect(() => usernameRef.current?.focus(), []);

  if (isAuthenticated) {
    return <Redirect to={routes.dashboardUrl} />
  }

  return (
    <Spin spinning={isLoading}>
      <div className="login__container">
        <img src={logo} alt="hub71-logo" className="login__logo" />
        <Form
          name="basic"
          onFinish={onFinish()}
          autoComplete="off"
          validateTrigger="onSubmit"
        >
          <Input
            label="Username"
            name="username"
            labelCol={{ span: 24 }}
            requiredMark={false}
            rules={[requiredValidation]}
            layout="vertical"
          >
            <Input.InputField ref={usernameRef as RefObject<InputRef>} />
          </Input>
          <Input
            label="Password"
            name="password"
            labelCol={{ span: 24 }}
            rules={[requiredValidation]}
            layout="vertical"
          >
            <Input.InputField type="password" />
          </Input>
          <div className="login__reset-password">
            <Link className="login__reset-password__link" to={routes.resetPasswordUrl}>RESET PASSWORD?</Link>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login__container__submit-button primary-button">
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
}

export default Login;
