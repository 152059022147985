import React from 'react';
import { Link } from 'react-router-dom';

import { RequestsProps } from '../../../types/startups/startupDetails';
import RequestRow from './components/RequestRow';
import { RequestType } from '../../../types/requests';
import identity from '../../../utils/identity';
import DataContainer from '../DataContainer';
import routes from '../../../routes';

import './Requests.scss';

const Requests = (props: RequestsProps) => {
  const { requests, showTitle, component } = props;

  return (
    <div className="requests">
      <DataContainer
        title={showTitle ? 'Requests' : ''}
        noDataText="requests"
        buttonText="request"
        hideButton
        data={identity.isEmptyArray(requests) ? null : (
          <>
            <div className="requests__container">
              {requests.map((request: RequestType) => <RequestRow component={component} request={request} key={`#R${request.id}`} />)}
            </div>
            <Link to={routes.requestsUrl} className="requests__view-all-btn">View All Requests</Link>
          </>
        )}
      />
    </div>
  );
}

Requests.defaultProps = {
  showTitle: true,
}

export default Requests;
