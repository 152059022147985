import React, { useEffect } from 'react';
import { Spin } from 'antd';

import { ServiceRequestItemType } from 'packages/service_request_repository';
import { UnsentServiceRequestsProps } from 'types/requests';
import { TitleWithActions, Input, RenderIf } from 'components/components';
import StaticNotification from 'components/components/Notification/StaticNotification';
import { identity } from 'utils';
import RequestRow from '../RequestItemRow';
import { RequestsTotal, AddMoreServices } from './components';

import './UnsentServiceRequests.scss';

const UnsentServiceRequests = (props: UnsentServiceRequestsProps) => {
  const { unsentRequests, highlightRequest, isLoading } = props;
  const { setSelectedServiceProvider, removeHighlight, setCurrentTab } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(unsentRequests)) {
      setCurrentTab({ currentTab: 'all' });
    } else {
      setSelectedServiceProvider({ id: unsentRequests[0].service?.serviceProvider?.id });
    }
  }, [unsentRequests]);

  useEffect(() => {
    const timer = setTimeout(() => {
      removeHighlight();
    }, 10000);
    return () => clearTimeout(timer);
  });

  return (
    <Spin spinning={isLoading}>
      <div className="requests-container">
        <RenderIf condition={highlightRequest && identity.isFullArray(unsentRequests)}>
          <StaticNotification
            type="success"
            message={(
              <span>
                Service
                <b>{` ${identity.isFullArray(unsentRequests) ? unsentRequests[0].service?.name : 'undefined'} `}</b>
                has been added successfully to your requests list!
              </span>
            )}
          />
        </RenderIf>
        <div className="unsent-requests">
          <TitleWithActions title="Service requests" />
          {unsentRequests.map((item: ServiceRequestItemType, index: number) => (
            <RequestRow
              highlight={identity.isZero(index) && highlightRequest}
              key={`request-${item.id}`}
              request={item}
              isUnsent
            />
          ))}
          <Input label="Add comment" layout="vertical">
            <Input.InputField.TextArea rows={3} placeholder="your comment goes here..." />
          </Input>
          <div className="unsent-requests--divider" />
          <RequestsTotal unsentRequests={unsentRequests} />
        </div>
        <AddMoreServices />
      </div>
    </Spin>
  );
}

export default UnsentServiceRequests;
