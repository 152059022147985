/* eslint-disable prefer-destructuring */
import pkg from '../../../../package.json';
import { HttpRequestConfig } from '../types';

type EnvVar = string;

const env = process.env as {
  [x: string]: string,
};

// export const DEFAULT_API_HOST: EnvVar = 'https://portal.hub71.maman.ae';
export const DEFAULT_API_HOST: EnvVar = 'https://rc1.hub71.app';

export const API_ROOT = '/api/d';

export const API_HOST: EnvVar = env.REACT_APP_API_HOST || DEFAULT_API_HOST;

export const VERSION: EnvVar = pkg.version;

export const NODE_ENV: EnvVar = env.NODE_ENV;

export const X_CSRF_TOKEN_HEADER: string = 'X-CSRF-TOKEN';

export const AUTHORIZATION_HEADER: string = 'Authorization';

export const CONTENT_TYPE_HEADER: string = 'content-type';

export const ANONYMOUS_ENDPOINTS = ['/api/d/auth/login', '/api/d/auth/resetpassword'];

export const MULTI_PART_FORM_HEADER_NAME = 'multipart/form-data';

export const DEFAULT_HEADERS = {
  Meta: [
    'web',
    NODE_ENV,
    VERSION,
  ].join(';'),
};

export const FORM_DATA_CONFIG: HttpRequestConfig = {
  headers: {
    [CONTENT_TYPE_HEADER]: MULTI_PART_FORM_HEADER_NAME,
  },
};

export const OPERATION_TYPE = {
  lessThan: 'lt',
  lessThanOrEqual: 'lte',
  greaterThan: 'gt',
  greaterThanOrEqual: 'gte',
  contains: 'icontains',
  in: 'in',
  startsWith: 'startswith',
  endsWith: 'endsWith',
}

export const AGGREGATIONS = {
  sum: 'sum',
  count: 'count',
  avg: 'avg',
  min: 'min',
  max: 'max',
}
