import React, {
  Ref, useEffect, useRef, useState,
} from 'react';
import { Button } from 'antd';

import { RequestItemCardProps } from 'types/requests';
import { Input, RenderIf } from 'components/components';
import { identity, parseNumberToPrice, positiveNumbersOnly } from 'utils';
import { CardRow, SpecialServiceRequestDetails } from './components';
import { PRICE_QUANTITY, PRICE_TYPE_TAG } from '../../../../utils';

import './RequestItemCard.scss';

const RequestItemCard = (props: RequestItemCardProps) => {
  const { item, editable, actions } = props;
  const {
    price,
    priceType,
    quantity,
    service,
    id,
    serviceRequestId,
    details,
    servicePrice,
  } = item;

  const [modify, setModify] = useState(false);
  const priceRef = useRef<HTMLInputElement>();

  const handleUpdate = () => {
    const priceValue = Number(priceRef.current?.value);
    if (identity.isTruthyNumber(priceValue)) {
      actions.updateRequestItemById({
        data: {
          id,
          price: priceValue * quantity!,
          serviceRequestId,
          servicePrice: servicePrice?.toPrice!,
        },
      });
    }
  }

  useEffect(() => {
    setModify(false);
  }, [price]);

  return (
    <div className="request-item-card">
      <h1 className="request-item-card__title">{ service?.name }</h1>
      <RenderIf condition={identity.isTruthyString(PRICE_TYPE_TAG[priceType!])}>
        <CardRow label="Pricing option" value={PRICE_TYPE_TAG[priceType!]} />
      </RenderIf>
      <CardRow
        label="Quantity"
        value={identity.isTruthyString(PRICE_QUANTITY[priceType!]) ? `${quantity} ${PRICE_QUANTITY[priceType!]}` : `${quantity}`}
      />
      <div className="request-item-card__row">
        <p className="request-item-card__row__label">
          {identity.isTruthyString(priceType) ? `Price per ${priceType?.split('-')[1]}` : 'Unit Price'}
        </p>
        <div className="dot" />
        { modify
          ? (
            <Input rules={[positiveNumbersOnly]} required>
              <Input.InputNumber
                min={0}
                ref={priceRef as Ref<HTMLInputElement>}
                defaultValue={price / quantity!}
                type="number"
              />
            </Input>
          )
          : (
            <p className="request-item-card__row__item">
              {parseNumberToPrice(price / quantity!, 'AED')}
            </p>
          )}
      </div>
      <CardRow label="Total" value={parseNumberToPrice(Number(price), 'AED')} bold />
      <SpecialServiceRequestDetails details={details} service={service} serviceRequestId={serviceRequestId} />
      <RenderIf condition={editable!}>
        <div className="request-item-card__buttons">
          {modify ? (
            <>
              <Button className="secondary-button" onClick={() => setModify(false)}>Discard changes</Button>
              <Button className="primary-button" onClick={handleUpdate}>Update request</Button>
            </>
          ) : (
            <Button className="secondary-button" onClick={() => setModify(true)}>
              Modify request
            </Button>
          )}
        </div>
      </RenderIf>
    </div>
  )
}

RequestItemCard.defaultProps = {
  editable: false,
}

export default RequestItemCard;
