import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { PartnerType } from 'packages/partner_repository';
import { composePartnerDetailsUrl } from 'routes/routeComposers';
import { PartnersOverviewProps } from 'types/partners/partnersOverview';
import {
  useTableHook,
  getRowKey,
  DRAWERS,
  isStartup,
  getOffset,
  isCommunity,
} from 'utils';
import config from 'assets/config';
import { GridTable, gridPaginationOptions, RenderIf } from '../components';
import { partnersColumns } from '../utils/constants/columns';
import PartnersOverviewTabs from './components/PartnersOverviewTabs';
import StaticNotification from '../components/Notification/StaticNotification';

import './PartnersOverview.scss';

const PartnersOverview = (props: PartnersOverviewProps) => {
  const {
    partners,
    isLoading,
    actions,
    userType,
    startupStatus,
    filtersActive,
  } = props;

  const { state, setLimit, setCurrentPage } = useTableHook();
  const { limit, currentPage } = state;

  const history = useHistory();

  useEffect(() => {
    if (!gridPaginationOptions.includes(limit)) {
      setLimit(config.GRID_TABLE_DEFAULT_LIMIT);
    }
  }, []);

  useEffect(() => {
    if (gridPaginationOptions.includes(limit)) {
      actions.getPartners({ limit, offset: getOffset(limit, currentPage) });
    }
  }, [limit, currentPage]);

  const getInTouchDrawer = (data: PartnerType) => {
    if (isStartup(userType)) {
      actions.setPartnerData({ data })
      actions.setDrawerBody({ drawerBody: DRAWERS.getInTouch })
      actions.setIsDrawerOpen({ isDrawerOpen: true });
    }
  }

  return (
    <div className="content-container">
      <div className="partners-overview requests-overview">
        <PartnersOverviewTabs tableState={state} partners={partners} />
        <RenderIf condition={filtersActive}>
          <StaticNotification type="warning" message="You are currently viewing filtered items. To view all items clear filters." />
        </RenderIf>
        <GridTable
          isLoading={isLoading}
          columns={partnersColumns}
          dataSource={partners}
          rowKey={getRowKey(partners, 'partners', 'id')}
          component="partners"
          firstButtonTitle="View profile"
          secondButtonTitle={isStartup(userType) ? 'Get in touch' : ''}
          isSecondButtonDisabled={!isCommunity(startupStatus)}
          firstButtonAction={(record: PartnerType) => history.push(composePartnerDetailsUrl(record?.id!))}
          hasDeleteOption={false}
          showCustomPagination
          limit={state.limit}
          setLimit={setLimit}
          currentPage={state.currentPage}
          setCurrentPage={setCurrentPage}
          secondButtonAction={isStartup(userType) ? getInTouchDrawer : null}
        />
      </div>
    </div>
  )
}

export default PartnersOverview;
