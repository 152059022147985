import React, { useState } from 'react';
import { Button } from 'antd';
import moment from 'moment';

import { RequestRowProps } from '../../../../../types/startups';
import { formatSlashMDY } from '../../../../utils/constants/formatters';
import ApprovalButtons from './components/ApprovalButtons';
import RequestDetailsModal from '../../../../RequestDetailsModal';

import './RequestRow.scss';

const RequestRow = (props: RequestRowProps) => {
  const {
    person: { name: personName, role },
    date,
    description,
    serviceRequest: { status },
    requestNumber,
    id,
  } = props.request;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="requests__request-row">
      <div className="requests__request-info">
        <Button
          onClick={() => setIsOpen(true)}
          className="requests__request-info--bottom"
        >
          {description}
        </Button>
        <div className="requests__request-info--top">
          <div className="requests__request-info--container">
            <p className="requests__request-info-text">{personName}</p>
            <div className="dot" />
            <p className="requests__request-info-text">{role}</p>
          </div>
          <div className="requests__request-info--container">
            <p className="requests__request-info-text">{`#R${requestNumber}`}</p>
            <div className="dot" />
            <p className="requests__request-info-text">{moment(date).format(formatSlashMDY)}</p>
          </div>
        </div>
        <RequestDetailsModal id={id!} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <ApprovalButtons component={props.component} requestId={id!} status={status} />
    </div>
  );
}

export default RequestRow;
