import { RequestsCountObject, RequestType } from 'types/requests';
import { RequestStateType } from 'types/reduxTypes/StateTypes';
import { defaultMember } from 'redux/utils/defaultStates/MemberDefaultState';
import { PartnerRequest } from 'packages/partner_repository';
import { ServiceRequestType } from 'packages/service_request_repository';
import { defaultServiceProvider } from './ServiceProvidersDefaults';
import { partnerDef } from './PartnersDefaults';
import { startupDef } from './StartupsDefaults';

const defaultPerson = {
  email: '',
  id: 0,
  name: '',
  phone: '',
  role: '',
  imageLink: '',
}

export const defaultRequest: RequestType = {
  quantity: 1,
  requestType: undefined,
  signedByStartup: false,
  signedByServiceProvider: false,
  date: '',
  description: '',
  id: 0,
  total: 'AED 0.00',
  otherDetails: '',
  person: defaultPerson,
  proposalDocument: '',
  proposalDuration: '',
  proposalTitle: '',
  requestNumber: 0,
  startup: { id: 0, imageLink: '', name: '' },
  service: {
    id: 0,
    name: '',
    serviceProvider: defaultServiceProvider,
    offerPrice: 'fixed',
    priceFrom: '',
    priceType: 'per-hour',
    priceTo: '',
    category: '',
    about: '',
    type: '',
    offerDocLink: '',
    additionalOptionPrice: '',
    additionalOptionName: '',
    startup: { id: 0, name: '' },
    serviceStatus: 'new',
    categoryId: 0,
  },
  serviceRequest: {
    status: 'new',
  },
  to: '',
  comments: [],
  teamMember: defaultMember,
  plan: '',
}

export const defaultServiceRequest: ServiceRequestType = {
  id: 0,
  itemCount: 0,
  itemList: [],
  serviceProviderId: 0,
  startupId: 0,
  status: 'unsent',
  totalPrice: 0,
}

export const requestsCountDefault: RequestsCountObject = {
  all: 0,
  pendingHUB71: 0,
  pendingQuotation: 0,
  inProgress: 0,
  new: 0,
  unsent: 0,
  delivered: 0,
  quoted: 0,
  rejected: 0,
}

const defaultPartnerRequest: PartnerRequest = {
  applicationDate: '',
  id: 0,
  message: '',
  partner: partnerDef,
  status: 'all',
  subject: '',
  videoURL: '',
  createdBy: {
    userName: '',
    userType: '',
    userId: '',
    startupId: 0,
    email: '',
  },
  pitchDeck: '',
  startup: startupDef,
  acknowledged: 0,
}

export const defaultRequestStateType: RequestStateType = {
  filtersActive: false,
  showInvoiceFields: false,
  servicesBySP: [],
  isLoading: false,
  currentTab: 'all',
  requests: [],
  unsentRequests: [],
  highlightRequest: false,
  highlightUnsentRequest: false,
  addedRequestsCount: 0,
  details: {
    data: defaultServiceRequest,
    partnerRequestData: defaultPartnerRequest,
    activeTab: 'Overview',
    comments: [],
    commentsLoading: false,
    documents: [],
  },
  items: [],
  partnerRequestId: 0,
  serviceRequestId: 0,
  count: requestsCountDefault,
  filters: {
    from: 0,
    to: 0,
    category: [],
    serviceProviderId: [],
    partnerId: [],
    startupId: [],
    type: 'all',
  },
}
