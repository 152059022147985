import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { changeRequestStatus } from '../../../../../../../redux/actions/request';
import { StateType } from '../../../../../../../types';
import { selectUserType } from '../../../../../../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ changeRequestStatus }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
