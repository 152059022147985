import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';

import { MemberType } from 'types/memberDetails';
import { identity } from 'utils';
import { RequestServiceDrawerProps } from 'types/services';
import { LabelValue } from 'types/componentTypes/Input';
import { InputColumns, ServiceRequestTotal } from './components';
import useRequestServiceHook from './utils/useRequestServiceHook';
import { DataColumns } from '../components';
import { getServiceRequestOverviewColumns } from './utils/constants';

import './RequestServiceDrawer.scss';

const RequestServiceDrawer = (props: RequestServiceDrawerProps) => {
  const { details, startupId, teamMembers } = props;
  const {
    setIsDrawerOpen,
    addNewRequest,
    getStartupTeamMembers,
    getWallet,
  } = props.actions;

  const {
    state,
    setService,
    setPriceType,
    setFurnished,
    setQuantity,
    setTeamMember,
    setPlan,
    setAccommodationType,
    setOfficeType,
    setTotalPrice,
    setIsApplyingForDependant,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsUserUAERes,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setRequestDocuments,
    setDisplayDocuments,
    setETAWithSignFile,
  } = useRequestServiceHook();

  const [displayAllColumns, setDisplayAllColumns] = useState(true)
  const columns: LabelValue[] = useMemo(
    () => getServiceRequestOverviewColumns(details, displayAllColumns),
    [details, displayAllColumns],
  );

  useEffect(() => {
    setService({ data: details });
  }, [details]);

  useEffect(() => {
    if (identity.isTruthyNumber(startupId)) {
      getStartupTeamMembers({ id: startupId });
      getWallet({ startup_id: startupId });
    }
  }, [startupId]);

  const teamMembersOptions = useMemo(() => {
    const options: { label: string, value: number }[] = [{ value: 0, label: 'Enter or choose from dropdown' }];
    teamMembers.map(({ id: value, firstName, lastName }: MemberType) => options.push({ value, label: `${firstName || 'Unknown team member'} ${lastName || ''}` }));
    return options;
  }, [teamMembers]);

  const checkVisibility = useMemo(() => [
    identity.isTruthyString(state.request.childPlaceOfBirth),
    identity.isTruthyString(state.request.isDependantUAE),
  ].some(Boolean), [state.request]);

  const noDocumentsUpload = useMemo(() => [
    identity.isTruthyNumber(state.request.teamMember?.id),
    identity.isTruthyString(state.request.plan),
    checkVisibility,
    identity.isTruthyString(state.request.relationToTM),
    identity.isTruthyString(state.request.dependantGender),
    identity.isTruthyString(state.request.dependantFullName),
    identity.isTruthyString(state.request.dependantDateOfBirth),
  ].every(Boolean), [state.request, checkVisibility]);

  const displayAddButton = useMemo(() => [
    details.categoryId !== 3,
    (details.categoryId === 3 && !state.request.isApplyingForDependant),
    state.request.displayDocuments,
  ].some(Boolean), [details, state.request]);

  useEffect(() => {
    setDisplayAllColumns(!state.request.displayDocuments!)
  }, [state.request.displayDocuments])

  const displayBackButton = useMemo(() => [
    state.request.displayDocuments,
    displayAddButton,
  ].every((Boolean)), [state.request]);

  return (
    <div className="request-service-details">
      <DataColumns columns={columns} />
      <InputColumns
        state={state}
        setPlan={setPlan}
        setTeamMember={setTeamMember}
        setPriceType={setPriceType}
        setQuantity={setQuantity}
        setFurnished={setFurnished}
        setAccommodationType={setAccommodationType}
        setOfficeType={setOfficeType}
        teamMembersOptions={teamMembersOptions}
        setIsApplyingForDependant={setIsApplyingForDependant}
        setRelationToTM={setRelationToTM}
        setIsDependantUAE={setIsDependantUAE}
        setDependantGender={setDependantGender}
        setDependantFullName={setDependantFullName}
        setDependantDateOfBirth={setDependantDateOfBirth}
        setChildPlaceOfBirth={setChildPlaceOfBirth}
        setIsUserUAERes={setIsUserUAERes}
        setPassportFile={setPassportFile}
        setVisaFile={setVisaFile}
        setVisaProofFile={setVisaProofFile}
        setEmiratesIDFile={setEmiratesIDFile}
        setRequestDocuments={setRequestDocuments}
        setETAWithSignFile={setETAWithSignFile}
      />
      <ServiceRequestTotal
        price={state.service.servicePrice?.toPrice || 0}
        quantity={state.request.quantity}
        setTotalPrice={setTotalPrice}
        totalPrice={state.request.totalPrice || 0}
      />
      <div className="submit__wrap">
        <Button
          className="secondary-button"
          onClick={() => (displayBackButton
            ? setDisplayDocuments({ data: false })
            : setIsDrawerOpen({ isDrawerOpen: false }))}
        >
          {displayBackButton ? 'go back' : 'discard'}
        </Button>
        <Button
          disabled={state.disabled}
          onClick={() => (displayAddButton
            ? addNewRequest({ data: state.request })
            : setDisplayDocuments({ data: noDocumentsUpload }))}
          className="primary-button"
        >
          {displayAddButton ? 'Add to requests' : 'Go to documents'}
        </Button>
      </div>
    </div>
  )
}

export default RequestServiceDrawer;
